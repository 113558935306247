import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "./reducers";
import localStorage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Disable console.log in production
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

// Setup middlewares based on the environment
const middlewares = [thunkMiddleware];

// Only add the logger in a development environment
if (process.env.NODE_ENV === "development") {
  const logger = createLogger({ collapsed: true });
  middlewares.push(logger);
}

var isdevtoool = "";
if (process.env.NODE_ENV === "development") {
  isdevtoool = [applyMiddleware(...middlewares), composeWithDevTools()];
} else {
  isdevtoool = [applyMiddleware(...middlewares)];
}

const enhancers = [applyMiddleware(...middlewares)];
const enhancer = compose(...enhancers);

const persistConfig = {
  key: "root",
  storage: localStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let Store = createStore(persistedReducer, {}, enhancer);
  let persistor = persistStore(Store);
  return { Store, persistor };
};
