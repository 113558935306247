import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  GlobleCategoryIdAction,
  applyFilterAction,
  clearAllFilter,
  filterTypesAction,
  getProductsAction,
  setCategoryCheckValue,
  setGenderCheckValue,
  setItemGroupCheckValue,
  setSubCategoryCheckValue,
} from "../../Redux/actions/productAction";
import { Box, Checkbox, Slider } from "@mui/material";
import {
  stoneValueChangeAction,
  weightValueChangeAction,
} from "../../Redux/actions/authAction";

function valuetext(value) {
  return `${value}°C`;
}

export default function Filter() {
  const dispatch = useDispatch();
  const {
    filterGenderList,
    filterSubCategory,
    filterCategory,
    filterItemGroup,
    filterLoading,
    filterError,
    filterValue,
  } = useSelector((state) => state.productState);

  const { stoneValue, weightValue } = useSelector((state) => state.authState);

  useEffect(() => {
    if (filterValue.length > 0) {
      dispatch(filterTypesAction());
    }
  }, [filterValue]);

  const handleChange = (event, newValue) => {
    dispatch(stoneValueChangeAction(newValue));
  };

  const handleChange1 = (event, newValue) => {
    dispatch(weightValueChangeAction(newValue));
  };

  const CLEARALL = () => {
    dispatch(clearAllFilter());
    dispatch(stoneValueChangeAction([0, 40]));
    dispatch(weightValueChangeAction([0, 150]));
  };

  const ApplyFilters = () => {
    dispatch(GlobleCategoryIdAction(""));
    dispatch(applyFilterAction());
    window.$("#exampleModal").modal("hide");
  };

  return (
    <>
      <div>
        <Accordion>
          <AccordionSummary
            className={
              filterGenderList?.some((item) => item.check == true)
                ? "bg-dark"
                : ""
            }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              fontFamily={"Montserrat"}
              fontSize={20}
              color={
                filterGenderList?.some((item) => item.check == true)
                  ? "red"
                  : "#000"
              }
            >
              Gender
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              {filterGenderList?.map((item) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.check ? true : false}
                        onChange={(e) => {
                          dispatch(setGenderCheckValue(item.id));
                        }}
                      />
                    }
                    label={item.name}
                  />
                </>
              ))}
            </FormControl>
          </AccordionDetails>
        </Accordion>
        {/* Item Group Filter */}
        <Accordion>
          <AccordionSummary
            className={
              filterItemGroup?.some((item) => item.check == true)
                ? "bg-dark"
                : ""
            }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              fontFamily={"Montserrat"}
              fontSize={20}
              color={
                filterItemGroup?.some((item) => item.check == true)
                  ? "red"
                  : "#000"
              }
            >
              Item Group
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              {filterItemGroup?.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.check ? true : false}
                      onChange={(e) => {
                        dispatch(setItemGroupCheckValue(item.id));
                      }}
                    />
                  }
                  label={item.name}
                />
              ))}
            </FormControl>
          </AccordionDetails>
        </Accordion>
        {/* Category Filter */}
        <Accordion>
          <AccordionSummary
            className={
              filterCategory?.some((item) => item.check == true)
                ? "bg-dark"
                : ""
            }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              fontFamily={"Montserrat"}
              fontSize={20}
              color={
                filterCategory?.some((item) => item.check == true)
                  ? "red"
                  : "#000"
              }
            >
              Category
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              {filterCategory?.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.check ? true : false}
                      onChange={(e) => {
                        dispatch(setCategoryCheckValue(item.id));
                      }}
                    />
                  }
                  label={item.name}
                />
              ))}
            </FormControl>
          </AccordionDetails>
        </Accordion>
        {/* Sub Category Filter */}
        {/* <Accordion>
          <AccordionSummary
            className={
              filterSubCategory?.some((item) => item.check == true)
                ? "bg-dark"
                : ""
            }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              fontFamily={"Montserrat"}
              fontSize={20}
              color={
                filterSubCategory?.some((item) => item.check == true)
                  ? "red"
                  : "#000"
              }
            >
              Sub Category
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              {filterSubCategory?.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.check ? true : false}
                      onChange={(e) => {
                        dispatch(setSubCategoryCheckValue(item.id));
                      }}
                    />
                  }
                  label={item.name}
                />
              ))}
            </FormControl>
          </AccordionDetails>
        </Accordion> */}
        {/* Stone Filter */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontFamily={"Montserrat"} fontSize={20} color={"#000"}>
              Stone %
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={[stoneValue[0], stoneValue[1]]}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={0}
              max={40}
            />

            <div className="justify-content-between d-flex">
              <div className=" ">
                <p>Min</p>
                <input
                  className="  text-center col-3"
                  type="text"
                  value={stoneValue[0]}
                />
              </div>
              <div className="text-end  ">
                <p>Max</p>
                <input
                  className="text-center col-3"
                  type="text"
                  value={stoneValue[1]}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion> */}
        {/* Weight Filter */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontFamily={"Montserrat"} fontSize={20} color={"#000"}>
              Weight
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={[weightValue[0], weightValue[1]]}
              onChange={handleChange1}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={0}
              max={150}
            />
            <div className="justify-content-between d-flex">
              <div className=" ">
                <p>Min</p>
                <input
                  className="  text-center col-3"
                  type="text"
                  value={weightValue[0]}
                />
              </div>
              <div className="text-end  ">
                <p>Max</p>
                <input
                  className="text-center col-3"
                  type="text"
                  value={weightValue[1]}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion> */}
        <div className="">
          <div
            onClick={CLEARALL}
            data-bs-dismiss="modal"
            className="btn btn-dark text-primary col-md-6 w-50 py-3"
          >
            CLEAR
          </div>
          <div
            onClick={ApplyFilters}
            className="btn btn-primary col-md-6 w-50 py-3"
          >
            APPLY
          </div>
        </div>
      </div>
    </>
  );
}
