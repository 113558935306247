import React from "react";
import { useDispatch } from "react-redux";
import { editOrderItemAction } from "../../Redux/actions/productAction";
import { Link, useNavigate } from "react-router-dom";

export default function OrderItem({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editOrderItemPress = () => {
    dispatch(editOrderItemAction(item.id, navigate));
  };

  const viewFullPdfPress = () => {
    navigate("/pdf");
  };

  return (
    <div className="col-md-4  mt-4  text-center">
      <div className="card">
        <div className="card-body">
          <h5
            className="card-title border-bottom pb-2"
            style={{ color: "#000" }}
          >
            Order ID #{item?.order_no}
          </h5>
          Order Data
          <p className="card-text">{item?.created_at}</p>
          {item.status === "0" || !item.status ? (
            <div className="row">
              <button
                className="btn btn-primary col-md-6"
                onClick={editOrderItemPress}
              >
                EDIT TO CART
              </button>
              <Link
                to={{ pathname: "/pdf" }}
                state={{
                  item: item.id,
                  itemId: item.id,
                  pdfUrl: `https://admin.aynajewels.in/amrut1api/order_temp/order_view/${item.id}`,
                }}
                className="btn btn-dark col-md-6"
                style={{ color: "#F25C4C" }}
              >
                PDF
              </Link>
            </div>
          ) : (
            <div className="row">
              <Link
                to={{ pathname: "/pdf" }}
                state={{
                  item: item.id,
                  itemId: item.id,
                  pdfUrl: `https://admin.aynajewels.in/amrut1api/order_temp/order_view/${item.id}`,
                }}
                className="btn btn-dark "
                onClick={viewFullPdfPress}
              >
                PDF
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
