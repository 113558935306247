import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./authReducer";
import productReducer from "./productReducer";

const appReducer = combineReducers({
  authState: authReducer,
  productState: productReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    storage.removeItem("persist:root");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
