import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLikeCardItemsAction } from "../Redux/actions/productAction";
import LikeItem from "./Components/LikeItem";

export default function Like() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    likeItemServerLoading,
    likeItemsLoading,
    likeItemsError,
    likeItems,
    likeListIds,
  } = useSelector((state) => state.productState);

  useEffect(() => {
    dispatch(getLikeCardItemsAction());
    document.title = "Wish List | AYNA ";
  }, []);

  return (
    <>
      {/* Spinner Start */}
      <div
        id="spinner"
        className={`${
          likeItemsLoading ? "show" : "hide"
        }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}

      {likeItemsError ||
      likeListIds.length === 0 ||
      (Array.isArray(likeItems) && likeItems.length === 0) ? (
        <>
          {(Array.isArray(likeItems) && likeItems.length === 0) ||
          likeListIds.length === 0 ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "80vh",
              }}
            >
              <div className="my-auto text-center">
                <img
                  src="assets/img/empty_wishlist.jpg"
                  height={300}
                  width={300}
                />
                <h4 className="my-2" style={{ color: "#000" }}>
                  your wish list is empty
                </h4>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/")}
                >
                  Continue shopping
                </button>
              </div>
            </div>
          ) : (
            <h1> {likeItemsError ? likeItemsError : "something is wrong"}</h1>
          )}
        </>
      ) : (
        <div className="mx-5 my-5 row ">
          {likeItems.map((item) => (
            <React.Fragment key={item.id}>
              <LikeItem item={item} />
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
}
