import React, { useState } from "react";
import { Link } from "react-router-dom";

const Carousel = ({ sliderList }) => {
  // const [data, setData] = useState(sliderList);
  const data = [
    {
      image:
        "https://static4.depositphotos.com/1000292/339/i/950/depositphotos_3394828-stock-photo-action-photo-of-carousel-on.jpg",
      caption: "Caption",
      description: "Description Here",
    },
    {
      image:
        "https://static4.depositphotos.com/1000292/339/i/950/depositphotos_3394828-stock-photo-action-photo-of-carousel-on.jpg",
      caption: "Caption",
      description: "Description Here",
    },
    {
      image:
        "https://static4.depositphotos.com/1000292/339/i/950/depositphotos_3394828-stock-photo-action-photo-of-carousel-on.jpg",
      caption: "Caption",
      description: "Description Here",
    },
  ];
  return (
    <div className="container-fluid p-0 mb-5">
      {/* <div
        id="header-carousel"
        className="carousel slide "
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {sliderList.map((item, index) => (
            <div
              className={index == 0 ? "carousel-item active" : "carousel-item "}
            >
              <img className=" w-100  " src={item.image} alt="Image" />
            </div>
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div> */}
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          {sliderList?.map((item, index) => (
            <div
              className={index == 0 ? "carousel-item active" : "carousel-item "}
            >
              <img className=" w-100  " src={item.image} alt="Image" />
            </div>
          ))}
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};
export default Carousel;
