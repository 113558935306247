import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      style={{
        height: "70vh",
      }}
    >
      <div className="" style={{ marginTop: "5%" }}>
        <div className="text-center ">
          <img src="assets/img/thankyou.jpg" height={300} width={300} />
          <p
            className=""
            style={{
              color: "#000",
              fontFamily: "Montserrat",
              fontSize: "17px",
            }}
          >
            {location?.state?.title ? location?.state?.title : "For Visiting"}
          </p>
          <button
            className="btn btn-primary"
            onClick={() =>
              navigate(location?.state?.path ? location?.state?.path : "/")
            }
          >
            {location?.state?.btntitle
              ? location?.state?.btntitle
              : "Continue to shoping"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
