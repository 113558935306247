import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderListAction } from "../Redux/actions/productAction";
import OrderItem from "./Components/OrderItem";

export default function Order() {
  const dispatch = useDispatch();
  const {
    listOrderLoading,
    listOrderItems,
    listOrderError,
    serverEditOrderServerLoadingError,
    webviewOrder,
  } = useSelector((state) => state.productState);

  useEffect(() => {
    dispatch(getOrderListAction());
    document.title = "Order | AYNA ";
  }, []);

  return (
    <>
      {listOrderLoading ? (
        <div
          id="spinner"
          className={`${
            listOrderLoading ? "show" : "hide"
          }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
        >
          <div
            className="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : listOrderError ||
        listOrderItems?.length === 0 ||
        (Array.isArray(listOrderItems) && listOrderItems?.length === 0) ? (
        <>
          {(Array.isArray(listOrderItems) && listOrderItems?.length === 0) ||
          listOrderItems?.length === 0 ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "100vh",
              }}
            >
              <h4 className="my-auto" style={{ color: "#000" }}>
                Your Order List Is Empty
              </h4>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="container my-5  ">
          <div className="row">
            {listOrderItems?.map((item) => (
              <OrderItem item={item} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
