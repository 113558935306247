import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useEventCallback,
} from "@mui/material";
import {
  addCardItemsAction,
  addRemoveLikeItemsAction,
  getGroupAndSizeId,
  setGroupModalValues,
  setSizeModalValues,
} from "../Redux/actions/productAction";
import SizeModal from "../Home/Cart/Components/SizeModal";
import GroupModal from "../Home/Cart/Components/GroupModal";
import axios from "axios";

export default function ProductDetail() {
  const location = useLocation();
  const { item } = location.state ? location.state : "";
  const {
    sizeListItems,
    groupListItems,
    incAndDecCardServerLoading,
    likeItemServerLoading,
    likeListIds,
  } = useSelector((state) => state.productState);

  const { userToken } = useSelector((state) => state.authState);

  const [stateLoading, setStateLoading] = useState(false);

  const [qtyValue, setQtyValue] = useState(1);
  const [text, onChangeText] = useState("");
  const [selectionItemId, setSelectionItemID] = useState(null);
  const [groupItemIdValue, setGroupItemValue] = useState(null);
  const [priceValue, setPriceValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [stoneValue, setStoneValue] = useState(null);
  const [grossWeight, setGrossWeight] = useState(null);
  const [stoneWeightValue, setStoneWeightValue] = useState(null);
  const [netWeightValue, setNetWeightValue] = useState(null);

  const [imageViewShow, setImageViewShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (item == undefined) {
      navigate("/");
    }
    dispatch(getGroupAndSizeId());
    document.title = "Product Detail | AYNA ";
  }, []);

  useEffect(() => {
    setPriceValue(item?.amount ? item?.amount : "");
    setTypeValue(item.item ? item.item : "");
    setCategoryValue(item.item_category ? item.item_category : "");
    setStoneValue(item.stone_per ? item.stone_per : "");
    setGrossWeight(item.gr ? item.gr : "");
    setStoneWeightValue(item.ls ? item.ls : "");
    setNetWeightValue(item.nt ? item.nt : "");

    setImageViewShow(false);
    onChangeText("");
    setQtyValue(1);
    const sizeIdValueObj = sizeListItems.find(
      (values) => values.id === item.size_id
    );
    const sizeIdValueValue =
      sizeIdValueObj && sizeIdValueObj.size ? sizeIdValueObj.size : null;
    setSelectionItemID(sizeIdValueValue);

    const groupIdValueObj = groupListItems.find(
      (values) => values.id === item.items_group_id
    );
    const groupIdValueValue =
      groupIdValueObj && groupIdValueObj.name ? groupIdValueObj.name : null;

    setGroupItemValue(groupIdValueValue);
  }, [item]);

  const addToCardItemFun = () => {
    const sizeIdValueObjFind = sizeListItems.find(
      (values) => values.size === selectionItemId
    );
    const sizeIdValueValueSetId =
      sizeIdValueObjFind && sizeIdValueObjFind.id
        ? sizeIdValueObjFind.id
        : null;

    const groupIdValueObjFind = groupListItems.find(
      (values) => values.name === groupItemIdValue
    );
    const groupIdValueValueId =
      groupIdValueObjFind && groupIdValueObjFind.id
        ? groupIdValueObjFind.id
        : null;

    dispatch(
      addCardItemsAction(
        item.id,
        qtyValue.toString(),
        "*",
        groupIdValueValueId
          ? groupIdValueValueId
          : item.items_group_id
          ? item.items_group_id
          : null,
        sizeIdValueValueSetId
          ? sizeIdValueValueSetId
          : item.size_id
          ? item.size_id
          : Array.isArray(sizeListItems) &&
            sizeListItems.length > 0 &&
            sizeListItems[0] &&
            sizeListItems[0].id
          ? sizeListItems[0].id
          : null,
        text,
        "addtocart"
      )
    );
  };

  const LikeToUnLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "unlike"));
  };

  const UnLikeToLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "like"));
  };

  const setSizeItemValuePress = () => {
    const sizeIdValueObjFind = sizeListItems.find(
      (values) => values.size === selectionItemId
    );
    const sizeIdValueValueSetId =
      sizeIdValueObjFind && sizeIdValueObjFind.id
        ? sizeIdValueObjFind.id
        : null;

    dispatch(setSizeModalValues(item, sizeIdValueValueSetId, true));
  };

  const onChangeSizeValue = (sizeValue) => {
    const sizeIdValueObj = sizeListItems.find(
      (values) => values.id === sizeValue
    );
    const sizeIdValueValueSet =
      sizeIdValueObj && sizeIdValueObj.size ? sizeIdValueObj.size : null;
    setSelectionItemID(sizeIdValueValueSet);
  };

  const setGroupItemValuePress = () => {
    const groupIdValueObj = groupListItems.find(
      (values) => values.name === groupItemIdValue
    );
    const groupIdValueObjValue =
      groupIdValueObj && groupIdValueObj.id ? groupIdValueObj.id : null;

    dispatch(setGroupModalValues(item, groupIdValueObjValue, true));
  };

  const onChangeGroupValue = (groupValueID) => {
    if (!(groupValueID == groupItemIdValue)) {
      var empty = JSON.stringify({
        design_code_id: parseInt(item.design_code_id),
        items_group_id: parseInt(groupValueID),
      });

      var config = {
        method: "post",
        url: `https://admin.aynajewels.in/amrut1api/items/product_details_change`,
        headers: {
          "If-Range": userToken,
          "Content-Type": "application/json",
        },
        data: empty,
      };

      setStateLoading(true);

      axios(config)
        .then(function (response) {
          setStateLoading(false);

          if (response && response.data && response.data.data) {
            setPriceValue(
              response.data.data?.amount ? response.data.data?.amount : ""
            );
            setTypeValue(
              response.data.data.item ? response.data.data.item : ""
            );
            setCategoryValue(
              response.data.data.item_category
                ? response.data.data.item_category
                : ""
            );
            setStoneValue(
              response.data.data.stone_per ? response.data.data.stone_per : ""
            );
            setGrossWeight(response.data.data.gr ? response.data.data.gr : "");
            setStoneWeightValue(
              response.data.data.ls ? response.data.data.ls : ""
            );
            setNetWeightValue(
              response.data.data.nt ? response.data.data.nt : ""
            );
          }
        })
        .catch(function (error) {
          setStateLoading(false);
        });
    }

    const groupIdValueObj = groupListItems.find(
      (values) => values.id === groupValueID
    );
    const groupIdValueObjValue =
      groupIdValueObj && groupIdValueObj.name ? groupIdValueObj.name : null;

    setGroupItemValue(groupIdValueObjValue);
  };

  return (
    <div className="container my-5 ">
      <div className="row">
        <div
          className="
     
        col-md-6 "
        >
          <div className="  ">
            <img
              class="img-fluid"
              src={item?.image}
              alt=""
              style={{
                height: "500px",
                width: "auto",
              }}
            />
          </div>
        </div>
        <div className="col-md-6 p-4 ">
          <div>
            <h1>{item?.design_name}</h1>
          </div>

          <div className="row">
            <div className="col-md-6 d-flex align-items-center ">
              <p className="fs-5  m-0">Quantity:</p>
            </div>
            <div className="col-md-6">
              <div class="input-group d-flex " style={{ width: "120px" }}>
                <div class="input-group-prepend">
                  <button
                    onClick={() => {
                      if (qtyValue > 1) {
                        setQtyValue((valueQty) => valueQty - 1);
                      }
                    }}
                    class="btn btn-outline-primary js-btn-minus"
                    type="button"
                  >
                    &minus;
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control text-center"
                  value={qtyValue}
                  placeholder=""
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                />
                <div class="input-group-append">
                  <button
                    onClick={() => {
                      setQtyValue((valueQty) => valueQty + 1);
                    }}
                    class="btn btn-outline-primary js-btn-plus"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6  ">
              <p className="fs-5  m-0">Remarks:</p>
            </div>
            <div className="col-md-6">
              <textarea
                type="text"
                className="form-control"
                value={text}
                onChange={(e) => onChangeText(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Size</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectionItemId}
                  label="Size"
                  onChange={(e) => {
                    setSelectionItemID(e.target.value);
                    dispatch(setSizeModalValues(item, e.target.value, true));
                  }}
                >
                  {sizeListItems.map((item) => (
                    <MenuItem value={item.id}>{item.size}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <button
                onClick={setSizeItemValuePress}
                className="btn btn-primary container-fluid"
                data-bs-toggle="modal"
                data-bs-target="#sizemodal"
              >
                Size:{selectionItemId ? selectionItemId : ""}
              </button>
              <SizeModal onChangeSizeValue={onChangeSizeValue} />
            </div>

            <div className="col-md-6">
              {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Group</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={groupItemIdValue}
                  label="Group"
                  onChange={(e) => {
                    setGroupItemValue(e.target.value);
                    dispatch(setGroupModalValues(item, e.target.value, true));
                  }}
                >
                  {groupListItems.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <button
                onClick={() => setGroupItemValuePress()}
                className="btn btn-primary  container-fluid "
                data-bs-toggle="modal"
                data-bs-target="#groupmodal"
              >
                Group: {groupItemIdValue ? groupItemIdValue : ""}
              </button>
              <GroupModal onChangeGroupValue={onChangeGroupValue} />
            </div>
          </div>

          <div className="bg-white py-1 text-center mt-4 ">
            <p className="m-0 text-primary fs-4">Product Details</p>
          </div>
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">Type</th>
                <td className="text-end"> {typeValue ? typeValue : "-"}</td>
              </tr>
              <tr>
                <th scope="row">Category</th>
                <td className="text-end">
                  {categoryValue ? categoryValue : "-"}
                </td>
              </tr>
              <tr>
                <th scope="row">Stone %</th>
                <td className="text-end">
                  {stoneValue ? `${stoneValue} %` : "-"}
                </td>
              </tr>
              <tr>
                <th scope="row">Gross Weight</th>
                <td className="text-end"> {grossWeight ? grossWeight : "-"}</td>
              </tr>{" "}
              <tr>
                <th scope="row">Stone Weight</th>
                <td className="text-end">
                  {stoneWeightValue ? stoneWeightValue : "-"}
                </td>
              </tr>{" "}
              <tr>
                <th scope="row">Net Weight</th>
                <td className="text-end">
                  {" "}
                  {netWeightValue ? netWeightValue : "-"}
                </td>
              </tr>{" "}
              <tr>
                <th scope="row">Stone Rs.</th>
                <td className="text-end"> {priceValue ? priceValue : "-"}</td>
              </tr>
            </tbody>
          </table>

          <div className="d-flex">
            <button
              className="btn btn-primary container-fluid"
              onClick={() => {
                if (likeListIds.find((likeitemid) => likeitemid === item.id)) {
                  LikeToUnLikeFun();
                } else {
                  UnLikeToLikeFun();
                }
              }}
            >
              {likeListIds.find((likeitemid) => likeitemid === item.id)
                ? "REMOVE TO WHSHILIST"
                : "ADD TO WHISHLIST"}
            </button>
            <button
              className="btn btn-dark container-fluid"
              onClick={addToCardItemFun}
            >
              ADD TO CART
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
