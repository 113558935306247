import React, { useEffect, useState } from "react";
import {
  RemoveCardItemsAction,
  addCardItemsAction,
  getGroupAndSizeId,
  setGroupModalValues,
  setRemarkModalValues,
  setSizeModalValues,
} from "../../../Redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useEventCallback,
} from "@mui/material";
import SizeModal from "./SizeModal";
import GroupModal from "./GroupModal";
import RemarkModal from "./RemarkModal";
import { Link } from "react-router-dom";

const CardItem = ({ item }) => {
  const dispatch = useDispatch();

  const { sizeListItems, groupListItems } = useSelector(
    (state) => state.productState
  );

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const DecementCardItemValueFun = () => {
    if (item.cart_qty > parseInt("1")) {
      dispatch(
        addCardItemsAction(
          item.id,
          item.cart_qty,
          "-",
          item.items_group_id,
          item.size_id,
          item.remark,
          "+"
        )
      );
    }
  };

  const IncementCardItemValueFun = () => {
    dispatch(
      addCardItemsAction(
        item.id,
        item.cart_qty,
        "+",
        item.items_group_id,
        item.size_id,
        item.remark,
        "+"
      )
    );
  };

  const RemovecardItemFun = () => {
    dispatch(RemoveCardItemsAction(item.id));
  };

  const RemarkValuePress = () => {
    dispatch(setRemarkModalValues(item, item.remark));
  };

  const setSizeItemValuePress = () => {
    dispatch(setSizeModalValues(item, item.size_id));
  };

  const setGroupItemValuePress = () => {
    dispatch(setGroupModalValues(item, item.items_group_id));
    window.$("#groupmodal").modal("show");
  };

  const sizeIdValueObj = sizeListItems.find(
    (values) => values.id === item.size_id
  );
  const sizeIdValueValue =
    sizeIdValueObj && sizeIdValueObj.size ? sizeIdValueObj.size : null;

  const groupIdValueObj = groupListItems.find(
    (values) => values.id === item.items_group_id
  );
  const groupIdValueValue =
    groupIdValueObj && groupIdValueObj.name ? groupIdValueObj.name : null;

  return (
    <div className="col-md-6 mt-4" data-wow-delay="0.1s">
      <div className="room-item shadow rounded overflow-hidden d-sm-flex  ">
        <div className="position-relative">
          <img
            className="img-fluid"
            src={item.image}
            alt=""
            style={{
              height: "260px",
              width: "-webkit-fill-available",
            }}
          />

          <Link
            state={{ item: item }}
            to={{ pathname: "/productdetail" }}
            style={{ margin: "20px auto", left: "5px" }}
            className="position-absolute top-20 translate-middle-y bg-primary text-white rounded py-1 px-3"
          >
            View
          </Link>
        </div>
        <div className="p-3">
          <div className="d-flex justify-content-between mb-2">
            <h5 className="mb-0 " style={{ color: "#000" }}>
              {item?.item}
            </h5>
            <i
              className="fa-solid fa-trash-can text-danger fs-3 "
              onClick={RemovecardItemFun}
            ></i>
          </div>
          <div>
            <p className="m-0  mb-1" style={{ color: "#000" }}>
              {item.design_name}
            </p>
          </div>
          <div className="d-flex ">
            <p className="border-end me-2 pe-2 ">RS: {item?.amount}</p>
            <p className=" me-2 pe-2">GW: {item.gr}</p>
            {/* <small className="border-end me-3 pe-3">{item.gr}</small> */}
          </div>
          <div className="d-flex align-items-center  justify-content-between">
            <div className="me-2">
              <button
                onClick={setSizeItemValuePress}
                className="btn btn-primary container-fluid"
                data-bs-toggle="modal"
                data-bs-target="#sizemodal"
              >
                Size:{sizeIdValueValue}
              </button>
              <SizeModal />
            </div>

            <div className="">
              <button
                onClick={() => setGroupItemValuePress()}
                className="btn btn-primary  container-fluid "
                data-bs-toggle="modal"
                data-bs-target="#groupmodal"
              >
                Group: {groupIdValueValue}
              </button>
              <GroupModal />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4 justify-content-between ">
            <div className="input-group d-flex" style={{ width: "120px" }}>
              <div className="input-group-prepend">
                <button
                  onClick={DecementCardItemValueFun}
                  className="btn btn-outline-primary js-btn-minus"
                  type="button"
                >
                  &minus;
                </button>
              </div>
              <input
                type="text"
                className="form-control text-center"
                value={item.cart_qty ? item.cart_qty : 1}
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
              />
              <div className="input-group-append">
                <button
                  onClick={IncementCardItemValueFun}
                  className="btn btn-outline-primary js-btn-plus"
                  type="button"
                >
                  +
                </button>
              </div>
            </div>

            <div
              style={{ width: "40%" }}
              className="me-2"
              data-bs-toggle="modal"
              data-bs-target="#remarkmodal"
              onClick={RemarkValuePress}
            >
              <p className=" border-bottom m-0">
                {item.remark && item.remark.length > 15
                  ? `${item.remark.substring(0, 15)}...`
                  : item.remark
                  ? item.remark
                  : "write remark"}
              </p>
            </div>
            <RemarkModal />
          </div>
          {/* <div className="d-flex justify-content-center mt-3 ">
            <button className="btn btn-dark container-fluid">
              View Detail
            </button>
          </div> */}
        </div>
      </div>

      <Link
        style={{ display: visible ? " " : "none" }}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        className="btn btn-lg btn-primary btn-lg-square back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </Link>
    </div>
  );
};

export default CardItem;
