import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LOG_OUT } from "../../Redux/actions/types";
import {
  LogOutAction,
  stoneValueChangeAction,
  weightValueChangeAction,
} from "../../Redux/actions/authAction";
import {
  applyFilterAction,
  clearAllFilter,
  getProductsAction,
  setSearchModelAction,
} from "../../Redux/actions/productAction";
import { useState } from "react";
import BackHeader from "../../BackHeader/BackHeader";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const logoutPress = () => {
    if (window.confirm("Are You Sure?")) {
      dispatch(LogOutAction());
    }
  };
  const { totalGrValue, cardTotalQty } = useSelector(
    (state) => state.productState
  );
  const { userName } = useSelector(
    (state) => state.authState
  );
  window.$(document).click(function (event) {
    var clickover = window.$(event.target);
    var $navbar = window.$(".navbar-collapse");
    var _opened = $navbar.hasClass("in");
    if (_opened === true && !clickover.hasClass("navbar-toggle")) {
      $navbar.collapse("hide");
    }
  });

  return (
    <div className="sticky-top">
      <div className="container-fluid bg-dark px-0 ">
        <div className="row gx-0">
          <div className="col-lg-3 bg-dark d-none d-lg-block">
            <Link
              to={"/"}
              className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center"
            >
              <img src="assets/img/logo-light.png" alt="" height={"50px"} />
            </Link>
          </div>
          <div className="col-lg-9">
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
              <Link to="/" className="navbar-brand d-block d-lg-none">
                {/* <h1 className="m-0 text-primary text-uppercase">AYNA</h1> */}
                <img src="assets/img/logo-light.png" alt="" height={"50px"} />
              </Link>
              <div className="text-center d-block d-lg-none">
                <div className="text-primary">{userName}</div>
              </div>
              <div className="text-center d-block d-lg-none">
                <div className="text-primary">{totalGrValue}</div>
                <div className="text-dark-emphasis">TOTAL</div>
              </div>

              <Link
                to="/cart"
                class=" position-relative me-3 d-block d-lg-none"
              >
                <i class="fa-solid fa-cart-shopping fs-4 "></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {cardTotalQty}
                  <span class="visually-hidden">unread messages</span>
                </span>
              </Link>

              <button
                type="button"
                className="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon text-dark-emphasis" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-between in"
                id="navbarCollapse"
              >
                <div className="navbar-nav mr-auto py-0">
                  <Link
                    to="/"
                    className={`nav-item nav-link ${
                      location.pathname === "/" ? "active" : ""
                    } `}
                  >
                    Home
                  </Link>

                  <Link
                    to="/product"
                    onClick={() => {
                      dispatch(clearAllFilter());
                      dispatch(stoneValueChangeAction([0, 40]));
                      dispatch(weightValueChangeAction([0, 150]));
                    }}
                    className={`nav-item nav-link ${
                      location.pathname === "/product" ? "active" : ""
                    } `}
                  >
                    Our Products
                  </Link>

                  <Link
                    to="/catalogue"
                    className={`nav-item nav-link ${
                      location.pathname === "/catalogue" ? "active" : ""
                    } `}
                  >
                    Catalogue
                  </Link>

                  <Link
                    to="/order"
                    className={`nav-item nav-link ${
                      location.pathname === "/order" ? "active" : ""
                    } `}
                  >
                    Order
                  </Link>

                  <div onClick={logoutPress} className="nav-item nav-link ">
                    {" "}
                    Log Out
                  </div>
                </div>
                {/* <div className="text-center d-none d-md-block d-sm-none">
                <div className="text-primary">{totalGrValue}</div>
                <div className="text-white">TOTAL</div>
              </div> */}
                {/* <nav class="navbar bg-body-tertiary">
                <div class="container-fluid">
                  <form class="d-flex" role="search">
                    <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={text}
                      onChange={(e) => onChangeText(e.target.value)}
                    />
                    <button
                      class="btn btn-outline-success"
                      onClick={SeachModelProductPress}
                    >
                      Search
                    </button>
                  </form>
                </div>
              </nav> */}
                <div className="rounded-0 py-1 px-md-5 d-flex justify-content-around align-items-center">
                <div className="text-center d-none d-md-block d-sm-none me-2">
                <div className="text-dark-emphasis">{userName}</div>
              </div>
                  <div className="text-center d-none d-md-block d-sm-none me-2">
                    <div className="text-primary">{totalGrValue}</div>
                    <div className="text-dark-emphasis">TOTAL</div>
                  </div>

                  <Link
                    to="/cart"
                    class=" position-relative me-3  d-block d-none  d-lg-block"
                  >
                    <i class="fa-solid fa-cart-shopping fs-4 "></i>
                    <span
                      class="position-absolute top-0 start-100 translate-middle badge rounded-pill "
                      style={{ backgroundColor: "#f5bda4", color: "#000" }}
                    >
                      {cardTotalQty}
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </Link>

                  <Link to="/like">
                    <i class="fa-solid fa-heart fs-4  me-3"></i>
                  </Link>
                  <Link data-bs-toggle="modal" data-bs-target="#searchmodal">
                    <i class="fa-solid fa-magnifying-glass fs-4"></i>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <BackHeader />
    </div>
  );
};

export default Header;
