import { dividerClasses } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const CatalogueView = () => {
  const location = useLocation();
  const { itemId, pdfUrl } = location.state;
  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
        <p>
          Alternative text - include a link{" "}
          <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a>
        </p>
      </object>
    </div>
  );
};

export default CatalogueView;
