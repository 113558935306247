import { useDispatch, useSelector } from "react-redux";
import Home from "./Home/Home";
import Login from "./Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import Header from "./Home/Components/Header";
import Product from "./Products/Product";
import { Toaster } from "react-hot-toast";
import Cart from "./Home/Cart/Cart";
import Like from "./Like/Like";
import Order from "./Order/Order";
import PdfView from "./Order/Components/PdfView";
import ProductDetail from "./ProductDetail/ProductDetail";
import ProtectedRoute from "./ProtectedRoute";
import SearchModal from "./Home/Components/SearchModal";
import NotFound from "./Home/Components/NotFound";
import Enquiry from "./Enquiry";
import Footer from "./Home/Components/Footer";
import BackHeader from "./BackHeader/BackHeader";
import { useEffect } from "react";
import { filterTypesAction } from "./Redux/actions/productAction";
import Catalogue from "./PDF/Catalogue";
import CatalogueView from "./PDF/Components/CatalogueView";
import ThankYou from "./Thank You/ThankYou";

function App() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.authState);
  const { filterValue } = useSelector((state) => state.productState);

  useEffect(() => {
    if (
      filterValue?.gender == "" &&
      filterValue?.itemGroup == "" &&
      filterValue?.category == "" &&
      filterValue?.subCategory == ""
    ) {
      dispatch(filterTypesAction());
    }
  }, [userToken]);

  return (
    <>
      <HashRouter>
        <Toaster />
        {userToken ? (
          <>
            <SearchModal />
            <Header />
          </>
        ) : null}

        <Routes>
          <Route
            path="/"
            element={userToken && "AYNA" ? <Home /> : <Login />}
          />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/product" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/like" element={<Like />} />
            <Route path="/order" element={<Order />} />
            <Route path="/pdf" element={<PdfView />} />
            <Route path="/productdetail" element={<ProductDetail />} />
            <Route path="/catalogue" element={<Catalogue />} />
            <Route path="/cataloguepdf" element={<CatalogueView />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        {userToken ? <Footer /> : null}
      </HashRouter>
    </>
  );
}

export default App;
