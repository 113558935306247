import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  getCardItemsAction,
  placeOrderCardItemAction,
} from "../../../Redux/actions/productAction";
import { useNavigate } from "react-router-dom";

export default function PlaceOrderModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [finish, onChangeFinish] = useState("t");
  const [rho, onChangeRho] = useState("t");
  const [stamp, setStamp] = useState("Color Diamond");
  const [screw, setScrew] = useState("north");
  const [path, setPath] = useState("with");

  const setPlaceOrderValues = () => {
    if (finish && rho && stamp && screw && path) {
      dispatch(
        placeOrderCardItemAction(stamp, finish, rho, screw, path, navigate)
      );
      onChangeFinish("");
      onChangeRho("");
      dispatch(getCardItemsAction());
      window.$("#placeorder").modal("hide");
    } else {
      toast.success("please fill all the fields");
    }
  };
  return (
    <div
      class="modal fade"
      id="placeorder"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1
              class="modal-title fs-5"
              id="placeorder"
              style={{ color: "#000" }}
            >
              Place Order Details:
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            {/* <div className="d-flex justify-content-between">
              <TextField
                id="standard-basic"
                label="Finishing"
                placeholder="Write about Finishing"
                variant="standard"
                onChange={(e) => onChangeFinish(e.target.value)}
                value={finish}
              />
              <TextField
                id="standard-basic"
                label="Rhodium"
                placeholder="Write about Rhodium"
                variant="standard"
                onChange={(e) => onChangeRho(e.target.value)}
                value={rho}
              />
            </div> */}

            <div className="row mt-3">
              <div className="col-md-12 row d-flex justify-content-center">
                <div className="col-md-6">
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className=" fs-5 text-primary"
                      // style={{ color: "#f5bda4" }}
                    >
                      Select Diamond Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Color Diamond"
                      name="radio-buttons-group"
                      onChange={(e) => setStamp(e.target.value)}
                      value={stamp}
                    >
                      <FormControlLabel
                        value="Color Diamond"
                        control={<Radio />}
                        label="Color Diamond"
                      />
                      <FormControlLabel
                        value="White Color Diamond"
                        control={<Radio />}
                        label="White Color Diamond"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {/* <div className="col-md-6 ">
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className=" fs-5 text-primary"
                      // style={{ color: "#f5bda4" }}
                    >
                      Select Screw
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="north"
                      name="radio-buttons-group"
                      onChange={(e) => setScrew(e.target.value)}
                      value={screw}
                    >
                      <FormControlLabel
                        value="north"
                        control={<Radio />}
                        label="North Screw"
                      />
                      <FormControlLabel
                        value="south"
                        control={<Radio />}
                        label="South Screw"
                      />
                    </RadioGroup>
                  </FormControl>
                </div> */}
              </div>
            </div>

            {/* <div className="row mt-3">
              <div className="col-md-12 row d-flex justify-content-between">
                <div className="col-md-6">
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="text-primary fs-5"
                      // style={{ color: "#f5bda4" }}
                    >
                      Select Patch Details
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="with"
                      name="radio-buttons-group"
                      onChange={(e) => setPath(e.target.value)}
                      value={path}
                    >
                      <FormControlLabel
                        value="with"
                        control={<Radio />}
                        label="With Patch"
                      />
                      <FormControlLabel
                        value="without"
                        control={<Radio />}
                        label="With Out Patch"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div> */}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={setPlaceOrderValues}
              type="button"
              class="btn btn-primary"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
