import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PdfView() {
  const location = useLocation();
  // const { pdfUrl } = location.state;
  const [loading, setLoading] = useState(false);
  const { userToken } = useSelector((state) => state.authState);

  useEffect(() => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("If-Range", userToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(location.state?.pdfUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) iframe.src = file;
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      {/* Spinner Start */}
      <div
        id="spinner"
        className={`${
          loading ? "show" : "hide"
        }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}

      <div className="">
        <iframe
          src=""
          width="100%"
          height="100%"
          style={{ height: "100vh" }}
        ></iframe>
      </div>
    </>
  );
}
