import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "./Redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, LoginInLoading } = useSelector((state) => state.authState);

  React.useEffect(() => {
    if (userToken) {
      navigate("/");
    } else {
    }
  }, [userToken]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const mobile = data.get("mobile");
    const password = data.get("password");

    if (mobile && password) {
      dispatch(LoginAction(mobile, password));
    }
  };
  return (
    <>
      {/* Spinner Start */}
      <div
        id="spinner"
        className={`${
          LoginInLoading ? "show" : "hide"
        }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
            }}
          >
            <img
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "20px",
              }}
              src="assets/img/logo-light.png"
              alt=""
              height={"70px"}
              width={"200px"}
            />

            <Typography
              component="h1"
              variant="h5"
              mt={2}
              className="text-primary"
            >
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="mobile"
                label="Mobile No."
                name="mobile"
                autoComplete="mobile"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                className="bg-primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item>
                  <Link
                    to="/enquiry"
                    variant="body2"
                    style={{ color: "#f5bda4" }}
                  >
                    Don't have an account? Enquiry
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
