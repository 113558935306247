import { TextField } from "@mui/material";
import React, { useState } from "react";
import {
  addCardItemsAction,
  setRemarkModalValues,
} from "../../../Redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";

export default function RemarkModal() {
  const dispatch = useDispatch();
  const { remarkProjectObj, remarKTextState, remarkModeState } = useSelector(
    (state) => state.productState
  );
  const [text, onChangeText] = useState(remarKTextState);
  const setMarkValue = () => {
    dispatch(
      addCardItemsAction(
        remarkProjectObj.id,
        "0",
        "*",
        remarkProjectObj.items_group_id,
        remarkProjectObj.size_id,
        text,
        "remark"
      )
    );
    onChangeText("");
    dispatch(setRemarkModalValues({}, ""));
    window.$("#remarkmodal").modal("hide");
  };
  return (
    <div
      class="modal fade"
      id="remarkmodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 text-primary" id="exampleModalLabel">
              Write Remark
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body ">
            <div className="d-flex justify-content-center">
              <TextField
                id="remark"
                placeholder="Remark"
                variant="standard"
                value={text}
                onChange={(e) => onChangeText(e.target.value)}
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={setMarkValue}
            >
              Set
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
