import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BackHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      {pathname === "/" ? null : (
        <>
          <div className="h-5 bg-white py-2 px-4 text-center">
            <i
              onClick={() => navigate(-1)}
              className="fa-solid fa-chevron-left fs-5"
              style={{ color: "#000", position: "absolute", left: "10px" }}
            ></i>
            <h5 style={{ color: "#000" }}>{pathname.slice(1).toUpperCase()}</h5>
          </div>
        </>
      )}
    </>
  );
};

export default BackHeader;
