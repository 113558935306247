import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useEventCallback,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardItemsAction,
  setSizeModalValues,
} from "../../../Redux/actions/productAction";

export default function SizeModal({ onChangeSizeValue }) {
  const dispatch = useDispatch();
  const {
    isProductDetailsState,
    sizeSetId,
    sizeProductObj,
    sizeModelState,
    sizeListItems,
    sizeList,
  } = useSelector((state) => state.productState);
  const [selectionItemId, setSelectionItemID] = useState(
    sizeSetId === "0" ? null : sizeSetId ? sizeSetId : null
  );

  useEffect(() => {
    setSelectionItemID(sizeSetId === "0" ? null : sizeSetId ? sizeSetId : null);
  }, [sizeSetId]);

  const setMarkValue = () => {
    if (isProductDetailsState) {
      onChangeSizeValue(selectionItemId);
    } else {
      dispatch(
        addCardItemsAction(
          sizeProductObj.id,
          "0",
          "*",
          sizeProductObj.items_group_id,
          selectionItemId,
          sizeProductObj.remark,
          "size"
        )
      );
    }
    dispatch(setSizeModalValues({}, ""));
    window.$("#sizemodal").modal("hide");
    // selectionItemId("");
  };

  return (
    <div
      class="modal fade"
      id="sizemodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 text-primary" id="exampleModalLabel">
              Select Item Size
            </h1>
            <button
              type="button"
              class="btn-close"
              // data-bs-dismiss="modal"
              // aria-label="Close"
              onClick={() => window.$("#sizemodal").modal("hide")}
            ></button>
          </div>
          <div class="modal-body ">
            <div className="flex-wrap d-flex px-5  justify-content-center">
              {sizeList?.map((item, index) => (
                <div className=" mt-2 me-2">
                  <button
                    onClick={() => setSelectionItemID(item.id)}
                    className={
                      selectionItemId === item.id
                        ? "btn  btn-primary"
                        : "btn btn-outline-primary"
                    }
                  >
                    {item.size}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div class="modal-footer">
            <button
              onClick={() => {
                dispatch(setSizeModalValues({}, ""));
                window.$("#sizemodal").modal("hide");
              }}
              type="button"
              class="btn btn-secondary"
              // data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={setMarkValue}
            >
              Set
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
