import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Catalogue = () => {
  const dispatch = useDispatch();
  const { serverEditOrderServerLoadingError, webviewOrder } = useSelector(
    (state) => state.productState
  );
  const { userToken } = useSelector((state) => state.authState);

  const [loadingDesign, setLoadingDesign] = useState(true);
  const [designListData, setDesignListData] = useState(false);
  const [designError, setDesignError] = useState(false);

  useEffect(() => {
    getDesignListAction();
    document.title = "Catelogue | AYNA ";
  }, []);

  const getDesignListAction = () => {
    var myHeaders = new Headers();
    myHeaders.append("If-Range", userToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://admin.aynajewels.in/amrut1api/catalogue`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status) {
          if (
            result?.data?.design_pdf &&
            Array.isArray(result?.data?.design_pdf) &&
            result?.data?.design_pdf.length > 0
          ) {
            setDesignError("");

            setDesignListData(result?.data?.design_pdf);
          } else {
            setDesignListData(result?.data?.design_pdf);
            setDesignError("No data found!");
          }
        } else {
          setDesignListData([]);
          setDesignError(
            result?.message ? result?.message : "Something went wrong!"
          );
        }
        setLoadingDesign(false);
      })
      .catch((error) => {
        setLoadingDesign(false);
        setDesignListData([]);
        setDesignError(
          error?.message ? error?.message : "Server response failed"
        );
      });
  };

  return (
    <>
      {loadingDesign ? (
        <div
          id="spinner"
          className={`${
            loadingDesign ? "show" : "hide"
          }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
        >
          <div
            className="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : designError ||
        designListData.length === 0 ||
        (Array.isArray(designListData) && designListData.length === 0) ? (
        <>
          {(Array.isArray(designListData) && designListData.length === 0) ||
          designListData.length === 0 ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "100vh",
              }}
            >
              <h4 className="my-auto" style={{ color: "#000" }}>
                No data found!
              </h4>
            </div>
          ) : (
            <h1 style={{ color: "#000" }}>
              {" "}
              {designError ? designError : "something is wrong"}
            </h1>
          )}
        </>
      ) : (
        <div
          className="container my-5  "
          style={{
            height: "100vh",
          }}
        >
          <div className="row">
            {designListData.map((item) => (
              <div className=" col-md-3  mb-4">
                <div class="card  ">
                  <div class="card-body">
                    <h5 class="card-title" style={{ color: "#000" }}>
                      {" "}
                      {item.title}
                    </h5>
                    <p class="card-text">{item?.sub_title}</p>
                    <Link
                      state={{
                        item: item,
                        itemId: item.id,
                        pdfUrl: item.image,
                      }}
                      to={"/cataloguepdf"}
                      class="btn btn-primary"
                    >
                      Open PDF
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Catalogue;
