import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
      id="footer"
      className="container-fluid bg-dark text-light footer wow fadeIn mt-4 aboutfooter "
      data-wow-delay="0.1s"
    >
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div
              className="col-md-6 text-center text-md-start mb-3 mb-md-0"
              style={{ color: "grey" }}
            >
              © {new Date().getFullYear()}, All Right Reserved.
              {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
              Designed By
              <Link
                className="border-bottom"
                to="https://rd.ragingdevelopers.com/react_amrut/"
                target="_blank"
                style={{ color: "#000" }}
              >
                {" "}
                AYNA
              </Link>
              <br />
              Developed By:
              <Link
                className="border-bottom"
                to="https://ragingdevelopers.com/"
                target="_blank"
                style={{ color: "#000" }}
              >
                {" "}
                Raging Developers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
