import { TextField } from "@mui/material";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  applyFilterAction,
  getProductsAction,
  setSearchModelAction,
} from "../../Redux/actions/productAction";

export default function SearchModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchModalShow, searchTextState } = useSelector(
    (state) => state.productState
  );

  const [text, onChangeText] = useState("");

  const SeachModelProductPress = () => {
    dispatch(setSearchModelAction(text));
    dispatch(applyFilterAction());
    dispatch(getProductsAction());
    navigate("/product");
    window.$("#searchmodal").modal("hide");
  };

  const clearSeachInputStatePress = () => {
    onChangeText("");
    dispatch(setSearchModelAction(""));
    dispatch(applyFilterAction());
    dispatch(getProductsAction());
    navigate("/product");
    window.$("#searchmodal").modal("hide");
  };
  return (
    <div
      class="modal fade"
      id="searchmodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1
              class="modal-title fs-5"
              id="exampleModalLabel"
              style={{ color: "#000" }}
            >
              Search Any Product
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body ">
            <div className="d-flex justify-content-center">
              <TextField
                id="search"
                placeholder="Search"
                variant="standard"
                value={text}
                onChange={(e) => onChangeText(e.target.value)}
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={clearSeachInputStatePress}
            >
              clear
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={SeachModelProductPress}
            >
              search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
