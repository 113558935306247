import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  HomePageAction,
  stoneValueChangeAction,
  weightValueChangeAction,
} from "../Redux/actions/authAction";
import Carousel from "./Components/Carousel.js";
import {
  GlobleCategoryIdAction,
  applyFilterAction,
  clearAllFilter,
  getCardItemsAction,
  getGroupAndSizeId,
  getLikeCardItemsAction,
  getProductsAction,
} from "../Redux/actions/productAction";
import SearchModal from "./Components/SearchModal";
import Footer from "./Components/Footer";
import ProgressiveImage from "react-progressive-graceful-image";
import { Margin } from "@mui/icons-material";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sliderList, homeItemList, weightValue, homeloading } = useSelector(
    (state) => state.authState
  );

  useEffect(() => {
    dispatch(HomePageAction());
    dispatch(getLikeCardItemsAction());
    dispatch(getGroupAndSizeId());
    dispatch(getCardItemsAction());
    dispatch(weightValueChangeAction([0, 150]));
    dispatch(stoneValueChangeAction([0, 40]));
    document.title = "Home | AYNA ";
  }, []);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="container-xxl bg-white p-0">
      <SearchModal />

      {homeloading ? (
        <div
          id="spinner"
          className={`${
            homeloading ? "show" : "hide"
          }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
        >
          <div
            className="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* Carousel */}
          <Carousel sliderList={sliderList} />

          {/* Room Start */}

          <div className="container-xxl ">
            <div className="container">
              <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title text-center text-primary text-uppercase">
                  Our Collections
                </h6>
                <h1 className="" style={{ color: "#f5bda4" }}>
                  Explore Our
                  <span className="text-primary text-uppercase">Jewellery</span>
                </h1>
              </div>

              {/* <div className="col-md-12"> */}
              <div className="row">
                {homeItemList?.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className="col-6 col-md-3 mt-4">
                        <div class="card h-100 hover-zoomin">
                          <div>
                            <ProgressiveImage
                              src={item.image}
                              placeholder="assets/img/demo.png"
                            >
                              {(src) => (
                                <img
                                  src={src}
                                  alt="an image"
                                  class="card-img-top h-100"
                                  onClick={() => {
                                    dispatch(clearAllFilter());
                                    dispatch(applyFilterAction(item.id));
                                    dispatch(GlobleCategoryIdAction(item.id));
                                    navigate("/product", {
                                      state: { category_id: item.id },
                                    });
                                  }}
                                />
                              )}
                            </ProgressiveImage>
                          </div>

                          <div class="card-body bg-light d-flex align-items-center justify-content-between">
                            <h5
                              style={{ color: "#000", margin: 0 }}
                              onClick={() => {
                                dispatch(clearAllFilter());
                                dispatch(applyFilterAction(item.id));
                                dispatch(GlobleCategoryIdAction(item.id));
                                navigate("/product", {
                                  state: { category_id: item.id },
                                });
                              }}
                            >
                              {item.name}
                            </h5>
                            <div
                              onClick={() => {
                                dispatch(clearAllFilter());
                                dispatch(applyFilterAction(item.id));
                                dispatch(GlobleCategoryIdAction(item.id));
                                navigate("/product", {
                                  state: { category_id: item.id },
                                });
                              }}
                              // to={{ pathname: "/productdetail" }}
                              // state={{ item: listOfProduct[index] }}
                              className="bg-primary text-white rounded py-1 px-3"
                            >
                              View
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* Room End */}

          {/* Footer Start */}
          {/* <Footer /> */}
          {/* Footer End */}
          {/* Back to Top */}
          <Link
            style={{ display: visible ? " " : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            className="btn btn-lg btn-primary btn-lg-square back-to-top"
          >
            <i className="bi bi-arrow-up" />
          </Link>
        </>
      )}
    </div>
  );
}
