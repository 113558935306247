export const SET_DARK_MODE = "SET_DARK_MODE";
export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const GET_FEATURED_NEWS = "GET_FEATURED_NEWS";
export const GET_TRENDING_NEWS = "GET_TRENDING_NEWS";

export const GET_NEWS_BY_ID = "GET_NEWS_BY_ID";

export const GET_UPCOMING_MATCHS = "GET_UPCOMING_MATCHS";

export const SORT_MODAL_TOGGLE = "SORT_MODAL_TOGGLE";

export const BG_COLOR_CHANGE = "BG_COLOR_CHANGE";

export const LOGIN_TO_GET_A_TOKEN = "LOGIN_TO_GET_A_TOKEN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const GUEST_USER_MODAL = "GUEST_USER_MODAL";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LOADING = "GET_PRODUCT_LOADING";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";
export const PRODUCT_RESET = "PRODUCT_RESET";

export const GET_HOME_PAGE_DETAILS = "GET_HOME_PAGE_DETAILS";
export const GET_HOME_PAGE_DETAILS_LOADING = "GET_HOME_PAGE_DETAILS_LOADING";
export const GET_HOME_PAGE_DETAILS_ERROR = "GET_HOME_PAGE_DETAILS_ERROR";
export const HOME_LOADING = "HOME_LOADING";
export const HOME_ERROR = "HOME_ERROR";

export const GET_FILTER_VALUE = "GET_FILTER_VALUE";
export const GET_FILTER_VALUE_LOADING = "GET_FILTER_VALUE_LOADING";
export const GET_FILTER_VALUE_ERROR = "GET_FILTER_VALUE_ERROR";

export const SET_GENDER_CHECK_VALUE = "SET_GENDER_CHECK_VALUE";
export const SET_SUB_CATEGORY_VALUE = "SET_SUB_CATEGORY_VALUE";
export const SET_CATEGORY_VALUE = "SET_CATEGORY_VALUE";
export const SET_ITEM_GROUP_VALUE = "SET_ITEM_GROUP_VALUE";

export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SET_FILTER = "SET_FILTER";

export const SET_LOADING_PAGINATION = "SET_LOADING_PAGINATION";
export const SET_PAGINATION_OFFSET = "SET_PAGINATION_OFFSET";
export const SET_PAGINATION_LIST_END = "SET_PAGINATION_LIST_END";

export const GET_CARD_ITEMS = "GET_CARD_ITEMS";
export const GET_CARD_ITEMS_LOADING = "GET_CARD_ITEMS_LOADING";
export const GET_CARD_ITEMS_ERROR = "GET_CARD_ITEMS_ERROR";

export const CARD_ITEM_INCREMENT = "CARD_ITEM_INCREMENT";
export const CARD_ITEM_DECREMENT = "CARD_ITEM_DECREMENT";
export const CARD_ITEM_INCREMENT_DECREMENT_SERVER_SIDE_LOADING =
  "CARD_ITEM_INCREMENT_DECREMENT_SERVER_SIDE_LOADING";
export const CARD_ITEM_INCREMENT_DECREMENT_SERVER_SIDE_ERROR_OR_DONE =
  "CARD_ITEM_INCREMENT_DECREMENT_SERVER_SIDE_ERROR_OR_DONE";

export const CARD_ITEM_VALUE_REMOVE = "CARD_ITEM_VALUE_REMOVE";
export const CARD_ITEM_VALUE_REMOVE_SERVER_SIDE_LOADING =
  "CARD_ITEM_VALUE_REMOVE_SERVER_SIDE_LOADING";
export const CARD_ITEM_VALUE_REMOVE_SERVER_SIDE_ERROR_OR_DONE =
  "CARD_ITEM_VALUE_REMOVE_SERVER_SIDE_ERROR_OR_DONE";

export const LOG_OUT = "LOG_OUT";
export const GET_LIKE_ITEMS = "GET_LIKE_ITEMS";
export const GET_LIKE_ITEMS_LOADING = "GET_LIKE_ITEMS_LOADING";
export const GET_LIKE_ITEMS_ERROR = "GET_LIKE_ITEMS_ERROR";

export const LIKE_UNLIKE_SERVER_SIDE_LOADING =
  "LIKE_UNLIKE_SERVER_SIDE_LOADING";
export const LIKE_UNLIKE_SERVER_SIDE_ERROR_DONE =
  "LIKE_UNLIKE_SERVER_SIDE_ERROR_DONE";

export const LIKE_ITEM_VALUE_ADD = "LIKE_ITEM_VALUE_ADD";
export const UNLIKE_ITEM_VALUE_REMOVE = "UNLIKE_ITEM_VALUE_REMOVE";

export const PLACE_ORDER_ITEMS = "PLACE_ORDER_ITEMS";
export const PLACE_ORDER_ITEMS_LOADING = "PLACE_ORDER_ITEMS_LOADING";
export const PLACE_ORDER_ITEMS_ERROR = "PLACE_ORDER_ITEMS_ERROR";

export const EDIT_ORDER_ITEM_LOADING = "EDIT_ORDER_ITEM_LOADING";
export const EDIT_ORDER_ITEM_BY_ID = "EDIT_ORDER_ITEM_BY_ID";
export const EDIT_ORDER_ITEM_ERROR = "EDIT_ORDER_ITEM_ERROR";

export const LIST_ORDER_ITEM_LOADING = "LIST_ORDER_ITEM_LOADING";
export const LIST_ORDER_ITEM = "LIST_ORDER_ITEM";
export const LIST_ORDER_ITEM_ERROR = "LIST_ORDER_ITEM_ERROR";

export const CHECK_UPDATE = "CHECK_UPDATE";
export const SET_SEARCH = "SET_SEARCH";
export const TOGGLE_SEARCH_MODEL = "TOGGLE_SEARCH_MODEL";

export const GET_GROUP_SIZE_LIST = "GET_GROUP_SIZE_LIST";
export const GET_GROUP_SIZE_LOADING = "GET_GROUP_SIZE_LOADING";
export const GET_GROUP_SIZE_LIST_ERROR = "GET_GROUP_SIZE_LIST_ERROR";

export const GET_SIZE_LIST = "GET_SIZE_LIST";
export const GET_SIZE_LOADING = "GET_SIZE_LOADING";
export const GET_SIZE_LIST_ERROR = "GET_SIZE_LIST_ERROR";

export const SET_REMARK_MODEL = "SET_REMARK_MODEL";
export const REMARK_ITEM_STATE_CHANGE = "SET_REMARK_ITEM_STATE_CHANGE";

export const SET_GROUP_MODEL = "SET_GROUP_MODEL";
export const SET_GROUP_ID_STATE_CHANGE = "SET_GROUP_ID_STATE_CHANGE";

export const SET_SIZE_MODEL = "SET_SIZE_MODEL";
export const SET_SIZE_ID_STATE_CHANGE = "SET_SIZE_ID_STATE_CHANGE";

export const ORDER_PDF_PAGE_HTML = "ORDER_PDF_PAGE_HTML";
export const ORDER_PED_PAGE_HTML_LOADING = "ORDER_PED_PAGE_HTML_LOADING";
export const ORDER_PDF_PAGE_HTML_ERROR = "ORDER_PDF_PAGE_HTML_ERROR";

export const ONCHANGE_STONE_VALUE = "ONCHANGE_STONE_VALUE";
export const ONCHANGE_WEIGHT_VALUE = "ONCHANGE_WEIGHT_VALUE";

export const SET_CARD_QTY_AND_TOTAL = "SET_CARD_QTY_AND_TOTAL";

export const PRODUCT_FOCUS_SCREEN = "PRODUCT_FOCUS_SCREEN";
export const PRODUCT_DETAIL_INDEX = "PRODUCT_DETAIL_INDEX";

export const ORDERBY_TOGGLE = "ORDERBY_TOGGLE";
export const GLOBLE_CATEGORY_ID = "GLOBLE_CATEGORY_ID";
