import React, { useState } from "react";
import { Link } from "react-router-dom";
import { addRemoveLikeItemsAction } from "../../Redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";

export default function LikeItem({ item }) {
  const dispatch = useDispatch();
  const { likeListIds } = useSelector((state) => state.productState);

  const LikeUnlikeItemFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "unlike"));
  };

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <div className="col-md-3 mt-4  wow fadeInUp " data-wow-delay="0.1s">
      {likeListIds.find((likeitemid) => likeitemid === item.id) ? (
        <div className="   shadow rounded card h-100 ">
          <div className="room-item   overflow-hidden ">
            <div className="position-relative">
              <i
                onClick={LikeUnlikeItemFun}
                class="fa-solid fa-heart position-absolute end-0 mt-1 me-2 fs-2 text-danger"
              ></i>
              <img
                className="img-fluid"
                src={item?.image}
                style={{
                  height: "250px",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
              {/* <Link
                to={{ pathname: "/productdetail" }}
                state={{ item: item }}
                className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4"
              >
                View
              </Link> */}
            </div>
            <div className="p-3">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="mb-0" style={{ color: "#000" }}>
                  {item?.item}
                </h5>
                <Link
                  to={{ pathname: "/productdetail" }}
                  state={{ item: item }}
                  className=" bg-primary text-white rounded py-1 px-3 "
                >
                  View
                </Link>
              </div>

              <div className="d-flex justify-content-between mb-2 ">
                <p className="mb-0" style={{ color: "#000" }}>
                  {item?.design_name}
                </p>
              </div>
              <div className="d-flex mb-2">
                <small className="border-end me-3 pe-3">GR: {item?.gr}</small>
                <small className=" me-3 pe-3">LS: {item?.ls}</small>
              </div>

              <div className="d-flex mb-2">
                <small className="border-end me-3 pe-3">NT: {item?.nt}</small>
                <small className=" me-3 ">Gender: {item?.gender}</small>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Link
        style={{ display: visible ? " " : "none" }}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        className="btn btn-lg btn-primary btn-lg-square back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </Link>
    </div>
  );
}
