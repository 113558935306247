import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useEventCallback,
} from "@mui/material";
import {
  ProductDetailIndexAction,
  addCardItemsAction,
  addRemoveLikeItemsAction,
  getGroupAndSizeId,
  getProductsAction,
  setGroupModalValues,
  setSizeModalValues,
  getSizeId,
} from "../Redux/actions/productAction";
import SizeModal from "../Home/Cart/Components/SizeModal";
import GroupModal from "../Home/Cart/Components/GroupModal";
import axios from "axios";

const ProductDetailModal = () => {
  const location = useLocation();

  const {
    sizeList,
    groupListItems,
    incAndDecCardServerLoading,
    likeItemServerLoading,
    likeListIds,
    listOfProduct,
    productindex,
    productdetailitem,
  } = useSelector((state) => state.productState);
  const [item, setItem] = useState([]);

  // listOfProduct[productindex];

  useEffect(() => {
    setPindex(productindex);
  }, [productindex]);

  useEffect(() => {
    // setItem(listOfProduct[productindex]);
    setItem(productdetailitem);
  }, [productdetailitem]);

  const { userToken } = useSelector((state) => state.authState);

  const [stateLoading, setStateLoading] = useState(false);

  const [qtyValue, setQtyValue] = useState(1);
  const [text, onChangeText] = useState("");
  const [selectionItemId, setSelectionItemID] = useState(null);
  const [groupItemIdValue, setGroupItemValue] = useState(null);
  const [priceValue, setPriceValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [stoneValue, setStoneValue] = useState(null);
  const [grossWeight, setGrossWeight] = useState(null);
  const [stoneWeightValue, setStoneWeightValue] = useState(null);
  const [netWeightValue, setNetWeightValue] = useState(null);
  const [pindex, setPindex] = useState(0);

  const [imageViewShow, setImageViewShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (item == undefined) {
      navigate("/");
    }
    dispatch(getGroupAndSizeId());
    document.title = "Product Detail | AYNA ";
  }, []);

  useEffect(() => {
    setItem(listOfProduct[pindex]);
  }, [pindex]);

  useEffect(() => {
    setPriceValue(item?.amount ? item?.amount : "");
    setTypeValue(item?.item ? item?.item : "");
    setCategoryValue(item?.item_category ? item?.item_category : "");
    setStoneValue(item?.stone_per ? item?.stone_per : "");
    setGrossWeight(item?.gr ? item?.gr : "");
    setStoneWeightValue(item?.ls ? item?.ls : "");
    setNetWeightValue(item?.nt ? item?.nt : "");

    setImageViewShow(false);
    onChangeText("");
    setQtyValue(1);
    const sizeIdValueObj = sizeList?.find(
      (values) => values.id === item?.size_id
    );
    const sizeIdValueValue =
      sizeIdValueObj && sizeIdValueObj.size ? sizeIdValueObj.size : null;
    setSelectionItemID(sizeIdValueValue);

    const groupIdValueObj = groupListItems.find(
      (values) => values.id === item?.items_group_id
    );
    const groupIdValueValue =
      groupIdValueObj && groupIdValueObj.name ? groupIdValueObj.name : null;

    setGroupItemValue(groupIdValueValue);
    dispatch(getSizeId(item?.item_id));
  }, [item]);

  console.log(sizeList, "sizeList");

  const addToCardItemFun = () => {
    const sizeIdValueObjFind = sizeList?.find(
      (values) => values.size === selectionItemId
    );
    const sizeIdValueValueSetId =
      sizeIdValueObjFind && sizeIdValueObjFind.id
        ? sizeIdValueObjFind.id
        : null;

    const groupIdValueObjFind = groupListItems.find(
      (values) => values.name === groupItemIdValue
    );
    const groupIdValueValueId =
      groupIdValueObjFind && groupIdValueObjFind.id
        ? groupIdValueObjFind.id
        : null;

    dispatch(
      addCardItemsAction(
        item?.id,
        qtyValue.toString(),
        "*",
        groupIdValueValueId
          ? groupIdValueValueId
          : item?.items_group_id
          ? item?.items_group_id
          : null,
        sizeIdValueValueSetId
          ? sizeIdValueValueSetId
          : item?.size_id
          ? item?.size_id
          : Array.isArray(sizeList) &&
            sizeList.length > 0 &&
            sizeList[0] &&
            sizeList[0].id
          ? sizeList[0].id
          : null,
        text,
        "addtocart"
      )
    );
  };

  const LikeToUnLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item?.id, "unlike"));
  };

  const UnLikeToLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item?.id, "like"));
  };

  const setSizeItemValuePress = () => {
    window.$("#sizemodal").modal("show");

    const sizeIdValueObjFind = sizeList?.find(
      (values) => values.size === selectionItemId
    );
    console.log(sizeIdValueObjFind, "sizeIdValueObjFind");
    const sizeIdValueValueSetId =
      sizeIdValueObjFind && sizeIdValueObjFind.id
        ? sizeIdValueObjFind.id
        : null;

    dispatch(setSizeModalValues(item, sizeIdValueValueSetId, true));
  };

  const onChangeSizeValue = (sizeValue) => {
    const sizeIdValueObj = sizeList?.find((values) => values.id === sizeValue);
    const sizeIdValueValueSet =
      sizeIdValueObj && sizeIdValueObj.size ? sizeIdValueObj.size : null;
    setSelectionItemID(sizeIdValueValueSet);
  };

  const setGroupItemValuePress = () => {
    const groupIdValueObj = groupListItems.find(
      (values) => values.name === groupItemIdValue
    );
    const groupIdValueObjValue =
      groupIdValueObj && groupIdValueObj.id ? groupIdValueObj.id : null;

    dispatch(setGroupModalValues(item, groupIdValueObjValue, true));
  };

  const onChangeGroupValue = (groupValueID) => {
    if (!(groupValueID == groupItemIdValue)) {
      var empty = JSON.stringify({
        design_code_id: parseInt(item?.design_code_id),
        items_group_id: parseInt(groupValueID),
      });

      var config = {
        method: "post",
        url: "http`://rd.ragingdevelopers.com/amrut/amrut1api/items/product_details_change",
        headers: {
          "If-Range": userToken,
          "Content-Type": "application/json",
        },
        data: empty,
      };

      setStateLoading(true);

      axios(config)
        .then(function (response) {
          setStateLoading(false);

          if (response && response.data && response.data.data) {
            setPriceValue(
              response.data.data?.amount ? response.data.data?.amount : ""
            );
            setTypeValue(
              response.data.data.item ? response.data.data.item : ""
            );
            setCategoryValue(
              response.data.data.item_category
                ? response.data.data.item_category
                : ""
            );
            setStoneValue(
              response.data.data.stone_per ? response.data.data.stone_per : ""
            );
            setGrossWeight(response.data.data.gr ? response.data.data.gr : "");
            setStoneWeightValue(
              response.data.data.ls ? response.data.data.ls : ""
            );
            setNetWeightValue(
              response.data.data.nt ? response.data.data.nt : ""
            );
          }
        })
        .catch(function (error) {
          setStateLoading(false);
        });
    }

    const groupIdValueObj = groupListItems.find(
      (values) => values.id === groupValueID
    );
    const groupIdValueObjValue =
      groupIdValueObj && groupIdValueObj.name ? groupIdValueObj.name : null;

    setGroupItemValue(groupIdValueObjValue);
  };

  // console.log(listOfProduct.length, "item");
  return (
    <>
      {item ? (
        <div
          class="modal fade "
          id="productdetailmodal"
          tabindex="-1"
          aria-labelledby="example"
          aria-hidden="true"
          style={{ overflowY: "scroll" }}
        >
          <div class="modal-dialog modal-lg" style={{ maxWidth: "1020px" }}>
            <div class="modal-content">
              <div class="modal-header">
                <h1
                  class="modal-title fs-5"
                  id="examplE"
                  style={{ color: "#000" }}
                >
                  Product Detail
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                  onClick={() => window.$("#productdetailmodal").modal("hide")}
                ></button>
              </div>
              <div class="modal-body">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="">
                        <img
                          class="img-fluid"
                          src={item?.image}
                          alt=""
                          style={{
                            height: "auto",
                            width: "auto",
                          }}
                        />
                      </div>
                      <div className="d-flex mt-2">
                        <button
                          onClick={() => {
                            if (pindex < 1) {
                            } else {
                              setPindex((pindex) => pindex - 1);
                            }
                          }}
                          className="btn btn-primary"
                          style={{ flex: 1, marginRight: "10px" }}
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => {
                            if (pindex >= Number(listOfProduct.length) - 1) {
                              dispatch(getProductsAction());
                            } else {
                              setPindex((pindex) => pindex + 1);
                            }
                          }}
                          className="btn btn-dark"
                          style={{ flex: 1, color: "#F25C4C" }}
                        >
                          next
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6  ">
                      {/* <div>
                        <h1>{item?.design_name}</h1>
                      </div> */}

                      <div className="bg-white py-1 text-center  ">
                        <p className="m-0 text-primary fs-4">Product Details</p>
                      </div>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th scope="row">Type</th>
                            <td className="text-end">
                              {" "}
                              {typeValue ? typeValue : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Category</th>
                            <td className="text-end">
                              {categoryValue ? categoryValue : "-"}
                            </td>
                          </tr>
                          {/* <tr>
                            <th scope="row">Stone %</th>
                            <td className="text-end">
                              {stoneValue ? `${stoneValue} %` : "-"}
                            </td>
                          </tr> */}
                          <tr>
                            <th scope="row">Gross Weight</th>
                            <td className="text-end">
                              {" "}
                              {grossWeight ? grossWeight : "-"}
                            </td>
                          </tr>{" "}
                          {/* <tr>
                            <th scope="row">Stone Weight</th>
                            <td className="text-end">
                              {stoneWeightValue ? stoneWeightValue : "-"}
                            </td>
                          </tr>{" "} */}
                          <tr>
                            <th scope="row">Net Weight</th>
                            <td className="text-end">
                              {" "}
                              {netWeightValue ? netWeightValue : "-"}
                            </td>
                          </tr>{" "}
                          <tr>
                            <th scope="row">Stone Rs.</th>
                            <td className="text-end">
                              {" "}
                              {priceValue ? priceValue : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="row  mx-1 mb-2">
                        <div className="col-md-6 col-6 p-0 pe-1">
                          <button
                            onClick={setSizeItemValuePress}
                            className="btn btn-primary container-fluid"
                            // data-bs-toggle="modal"
                            // data-bs-target="#sizemodal"
                            // data-bs-dismiss="modal"
                          >
                            Size:{selectionItemId ? selectionItemId : ""}
                          </button>
                          <SizeModal onChangeSizeValue={onChangeSizeValue} />
                        </div>

                        <div className="col-md-6 col-6 p-0 ps-1">
                          <button
                            onClick={() => setGroupItemValuePress()}
                            className="btn btn-dark container-fluid "
                            data-bs-toggle="modal"
                            data-bs-target="#groupmodal"
                            style={{ color: "#F25C4C" }}
                          >
                            Group: {groupItemIdValue ? groupItemIdValue : ""}
                          </button>
                          <GroupModal onChangeGroupValue={onChangeGroupValue} />
                        </div>
                      </div>

                      <div className=" d-flex align-items-center mx-2 my-3 ">
                        {/* <div className="col-md-6 d-flex align-items-center ">
                          <p className="fs-5  m-0">Quantity:</p>
                        </div> */}
                        <div className="me-3 mx-auto" style={{ flex: 1 }}>
                          <div className="mx-3">
                            <div class="input-group d-flex ">
                              <div class="input-group-prepend">
                                <button
                                  onClick={() => {
                                    if (qtyValue > 1) {
                                      setQtyValue((valueQty) => valueQty - 1);
                                    }
                                  }}
                                  class="btn btn-outline-primary js-btn-minus"
                                  type="button"
                                >
                                  &minus;
                                </button>
                              </div>
                              <input
                                type="text"
                                class="form-control text-center"
                                value={qtyValue}
                                placeholder=""
                                aria-label="Example text with button addon"
                                aria-describedby="button-addon1"
                              />
                              <div class="input-group-append">
                                <button
                                  onClick={() => {
                                    setQtyValue((valueQty) => valueQty + 1);
                                  }}
                                  class="btn btn-outline-primary js-btn-plus"
                                  type="button"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="" style={{ flex: 1 }}>
                          {/* <div className="col-md-6  ">
                          <p className="fs-5  m-0">Remarks:</p>
                        </div> */}

                          <textarea
                            placeholder="Remark"
                            type="text"
                            className="form-control"
                            value={text}
                            onChange={(e) => onChangeText(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div className="d-flex mt-2">
                        <button
                          className="btn btn-primary container-fluid me-2"
                          onClick={() => {
                            if (
                              likeListIds.find(
                                (likeitemid) => likeitemid === item?.id
                              )
                            ) {
                              LikeToUnLikeFun();
                            } else {
                              UnLikeToLikeFun();
                            }
                          }}
                        >
                          {likeListIds.find(
                            (likeitemid) => likeitemid === item?.id
                          )
                            ? "REMOVE TO WHSHILIST"
                            : "ADD TO WHISHLIST"}
                        </button>
                        <button
                          className="btn btn-dark container-fluid"
                          onClick={addToCardItemFun}
                          style={{ color: "#F25C4C" }}
                        >
                          ADD TO CART
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductDetailModal;
