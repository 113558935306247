import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useEventCallback,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardItemsAction,
  setGroupModalValues,
} from "../../../Redux/actions/productAction";

export default function GroupModal({ onChangeGroupValue }) {
  const dispatch = useDispatch();
  const {
    isProductDetailsState,
    groupSetId,
    groupProjectObj,
    groupModelState,
    groupListItems,
  } = useSelector((state) => state.productState);
  const [selectionItemId, setSelectionItemID] = useState(groupSetId);

  useEffect(() => {
    setSelectionItemID(groupSetId);
  }, [groupSetId]);

  const setMarkValue = () => {
    if (isProductDetailsState) {
      onChangeGroupValue(selectionItemId);
    } else {
      dispatch(
        addCardItemsAction(
          groupProjectObj.id,
          "0",
          "*",
          selectionItemId,
          groupProjectObj.size_id,
          groupProjectObj.remark,
          "group"
        )
      );
    }
    dispatch(setGroupModalValues({}, ""));
    window.$("#groupmodal").modal("hide");
    // selectionItemId(null);
  };

  return (
    <div
      class="modal fade"
      id="groupmodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 text-primary" id="exampleModalLabel">
              Select Item Size
            </h1>
            <button
              type="button"
              class="btn-close"
              // data-bs-dismiss="modal"
              // aria-label="Close"
              onClick={() => window.$("#groupmodal").modal("hide")}
            ></button>
          </div>
          <div class="modal-body ">
            <div className="flex-wrap d-flex px-5  justify-content-center">
              {groupListItems?.map((item, index) => (
                <div className=" mt-2 me-2">
                  <button
                    onClick={() => setSelectionItemID(item.id)}
                    className={
                      selectionItemId === item.id
                        ? "btn  btn-primary"
                        : "btn btn-outline-primary"
                    }
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div class="modal-footer">
            <button
              onClick={() => {
                dispatch(setGroupModalValues({}, ""));
                window.$("#groupmodal").modal("hide");
              }}
              type="button"
              class="btn btn-secondary"
              // data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={setMarkValue}
            >
              Set
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
