import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardItem from "./Components/CardItem";
import {
  getCardItemsAction,
  placeOrderCardItemAction,
} from "../../Redux/actions/productAction";
import PlaceOrderModal from "./Components/PlaceOrderModal";
import { useNavigate } from "react-router-dom";

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    incAndDecCardServerLoading,
    removeCardServerLoading,
    cardItemsLoading,
    cardItemsError,
    cardItems,
    serverPlaceServerLoadingError,
    listOfProduct,
    paginationLoading,
  } = useSelector((state) => state.productState);

  useEffect(() => {
    dispatch(getCardItemsAction());
    document.title = "Cart | AYNA ";
  }, []);

  return (
    <>
      {/* Spinner Start */}
      <div
        id="spinner"
        className={`${
          cardItemsLoading ? "show" : "hide"
        }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}

      <PlaceOrderModal />

      {cardItemsError ||
      (Array.isArray(cardItems) && cardItems.length === 0) ? (
        <>
          {Array.isArray(cardItems) && cardItems.length === 0 ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "80vh",
              }}
            >
              <div className="my-auto text-center">
                <img
                  src="assets/img/empty_cart.avif"
                  height={200}
                  width={200}
                />
                <h4 className="my-2 text-primary">YOUR CART IS EMPTY</h4>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/")}
                >
                  Continue shopping
                </button>
              </div>
            </div>
          ) : (
            <h1> {cardItemsError}</h1>
          )}
        </>
      ) : (
        <>
          <div className="container my-5">
            <div className="row ">
              {cardItems.map((item) => (
                <React.Fragment key={item.id}>
                  <CardItem item={item} />
                </React.Fragment>
              ))}
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary fixed-bottom"
            data-bs-toggle="modal"
            data-bs-target="#placeorder"
          >
            Place Order
          </button>
        </>
      )}
    </>
  );
}
